<template>
  <div>
    正在登录...
  </div>
</template>

<script>
import { login } from "@/apis/account";
import cookie from "@/utils/cookie";

export default {
  name: "WxAuthCallback",
  created() {
    const to = decodeURIComponent(this.$route.query.to);
    const env = this.$route.query.env;
    const origin = decodeURIComponent(this.$route.query.origin);
    const from_wx = this.$route.query.from_wx;
    const code = this.$route.query.code;
    console.log("callback", {
      to, env, origin, from_wx, code
    });
    if (from_wx === "1") {
      //需要重定向至对应的环境
      const redirect = origin + "/wxAuth_callback" +
        "?to=" + encodeURIComponent(to)
        + "&env=" + process.env.NODE_ENV
        + "&code=" + code;
      window.location.replace(redirect);
    } else {
      //已经到当前页了
      login(code).then(res => {
        console.log(res.data);
        if (res.data) {
          cookie.setCookie({
            token: res.data.token
          }, 7);

          window.location.replace(to);

        }
      });
    }
  },
  methods: {}
};
</script>

<style scoped>

</style>